import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import './HomePage.css'

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  button,
  subtitle,
  featuredImage,
  body,
  description,
  descriptionTitle,
  imagesDescription
}) => (
  <main className="Home">
    <PageHeader
      large
      button={button}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <div className="Description-Site">
      {descriptionTitle && (
        <p className="Description-Site--Title">{descriptionTitle}</p>
      )}
      {description && (
        <p className="Description-Site--Subtitle">{description}</p>
      )}
    </div>

    {imagesDescription && (
      <div className="Description-Site--Images-Container">
        {Object.values(imagesDescription).map((image, index) => (
          <img
            loading="lazy"
            src={image}
            className="Description-Site--Image"
            alt={image}
            key={index}
          />
        ))}
      </div>
    )}

    {/* {body && (
      <section className="section">
        <div className="container">
          <Content source={body} />
        </div>
      </section>
    )} */}
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        button
        description
        descriptionTitle
        subtitle
        featuredImage
        imagesDescription {
          image1
          image2
          image3
        }
      }
    }
  }
`
